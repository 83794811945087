<template>
    <router-link :to="{ name: 'web-app-profile', params: { displayName: user.displayname } }" class="user-picture-name-followers">
        <div class="row no-gutters text-truncate">
            <div v-if="user.photo" class="col-auto">
                <user-picture :is-verified="Boolean(Number(user.verify))" :picture="user.photo.url" />
            </div>
            <div class="col col-sm-auto d-flex justify-content-center flex-column">
                <user-name :name="`${user.firstname} ${user.lastname}`" />
                <user-nickname class="text-truncate" :user-nickname="user.displayname" />
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    name: "UserPictureNameFollowers",
    components: {
        UserPicture: () => import(/* webpackChunkName: "user-picture" */ "@c/atoms/user-picture"),
        UserName: () => import(/* webpackChunkName: "user-name" */ "@c/atoms/user-name"),
        UserNickname: () => import(/* webpackChunkName: "user-nickname" */ "@c/atoms/user-nickname")
    },
    props: {
        user: {
            type: Object,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.user-picture-name-followers {
    display: flex;
    text-decoration: none;

    /deep/ .user-picture {
        margin-right: 12px;

        @media(max-width: $sm) {
            margin-right: 10px;
        }
    }

    /deep/ .user-name {
        margin-bottom: 4px;
        color: white;

        @media(max-width: $sm) {
            margin-bottom: 0;
        }
    }
}
</style>
